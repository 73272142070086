@import 'https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css';
@import 'https://fonts.googleapis.com/css?family=Mukta|Nunito|Oxygen|Poppins|Quicksand|Work+Sans&display=swap';
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta2/css/all.min.css';

body
{
    padding: 0;
    margin: 0;
    font-family: 'Quicksand', sans-serif;
}

#root
{
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
}

.btn
{
    border: none;
}






div.page-main
{
    display: flex;
    flex-direction: row;
    height: 100%;
    max-height: 100%;
}

div.page-main .sidebar
{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 80%;
    background-color: #333;
    border-right: 2px solid #000;
    z-index: 1000;
}

.sidebar-cover
{
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .7);
}

div.page-main .toggle-sidebar
{
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1000;
}

div.page-main .sidebar h4
{
    margin: 0;
    padding: 15px;
    color: #FFF;
    font-size: 22px;
    background-color: rgba(255, 255, 255, .3);
}

div.page-main .content
{
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    max-height: 100%;
    position: relative;
}

div.page-main .content .top-bar
{
    border-bottom: 1px solid #CCC;
    padding: 10px;
    display: flex;
    background-color: #333;
    color: #FFF;
}

div.page-main .content .top-bar button
{
    margin-right: 20px;
    border-radius: 6px;
}

div.page-main .content .top-bar h1
{
    font-size: 22px;
    margin: 0;
    padding: 0;
    line-height: unset;
}

div.page-main .content .action-bar .btn
{
    border-radius: 0px;
}