.conversation-list
{
    margin: 0;
    padding: 0;
    list-style: none;
}

.conversation-list li
{
    padding: 10px;
    color: #FFF;
    border-bottom: 1px solid #666;
    display: flex;
}

.conversation-list li:hover
{
    background-color: rgba(255, 255, 255, .1);
}

.conversation-list li .avatar
{
    border-radius: 50%;
    border: 2px solid #FFF;
    height: 80px;
    margin-right: 15px;
}

.conversation-list li p
{
    display: inline;
    margin: 0;
}

.conversation-list li .user
{
    font-weight: bold;
}

.no-conversations
{
    text-align: center;
    padding: 20px;
    color: #CCC;
}