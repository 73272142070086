.my-profile
{
    text-align: center;
    padding: 20px 15px;
    border-bottom: 1px solid #222;
    display: flex;
    flex-direction: column;
}

.my-profile .my-avatar
{
    border-radius: 50%;
    height: 150px;
    margin: auto;
    margin-bottom: 15px;
}

.avatar-list
{
    background-color: rgba(255, 255, 255, .1);
    padding: 5px;
    margin-bottom: 15px;
}

.avatar-list .avatar
{
    padding: 5px;
}

.avatar-list .avatar > img
{
    max-width: 100%;
}