.conversation
{
    flex: 1;
    height: 100%;
    max-height: 100%;
    overflow-y: scroll;
    padding: 15px;
    background-image: url('../../img/conversation-bg.png');
}

.conversation .not-selected
{
    text-align: center;
    padding: 25px 10px;
    color: #777;
    background-color: #FFF;
    border-radius: 10;
}

.conversation .empty-conversation
{
    text-align: center;
    padding: 25px 10px;
    color: #777;
    background-color: #FFF;
    border-radius: 10;
}

.conversation .entry
{
    width: 75%;
    margin-bottom: 30px;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
}

.conversation .entry.right
{
    margin-left: auto;
    text-align: right;
}

.conversation .entry .meta
{

}

.conversation .entry .content
{
    display: inline-block;
    background-color: #FFF;
    padding: 15px;
    border-radius: 6px;
}

.conversation .entry .content p
{
    margin: 0;
}

.conversation .entry .content p.question-translated
{
    color: #999;
}

.conversation .entry .choose-answer
{
    margin-top: 15px;
}

.conversation .entry .choose-answer .answer
{
    border: 1px solid #CCC;
    border-radius: 5px;
    padding: 10px;
    background-color: #FFF;
    margin-bottom: 5px;
}