.start-chat
{
    display: flex;
    flex: 1;
    height: 100%;
    max-height: 100%;
    overflow-y: scroll;
    padding: 15px;
    background-image: url('../../img/conversation-bg.png');
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.start-chat .wrap
{
    flex: 1;
}
