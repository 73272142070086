.browse-topics
{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 48px;
    background-color: rgba(0, 0, 0, .9);
    height: 60%;
}

.browse-topics .inside
{
    height: 100%;
    overflow-y: scroll;
    padding: 25px;
    color: #FFF;
}

.browse-topics .topics-grid .topic
{
    border: 1px solid #666;
    padding: 10px;
    text-align: center;
    background-color: rgba(255, 255, 255, .1);
    margin-bottom: 30px;
}

.browse-topics .topics-grid .topic .thumb
{
    max-width: 100%;
}

.browse-topics .questions-grid .question
{
    border: 1px solid #666;
    padding: 10px;
    background-color: rgba(255, 255, 255, .1);
    margin-bottom: 15px;
}