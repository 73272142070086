@media (min-width: 768px) {
    div.page-main .sidebar
    {
        position: relative;
        display: block !important;
        width: 300px;
    }

    .sidebar-cover
    {
        display: none !important;
    }

    div.page-main .content
    {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 100%;
        max-height: 100%;
    }
}